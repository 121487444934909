<template>
  <div class="max-vertical-height">
    <breadcrumbs title="Checkout"
      :crumbs="[
        { name: store.name, route: 'Store', params: { link: store.link } },
        { name: 'Checkout', route: 'Checkout', params: { link: store.link } }]">
    </breadcrumbs>
    <vs-popup title="Please wait..." :button-close-hidden="true" :active="submittingOrder || placeOrderClicked">
      <p style="text-align: center; font-size: 16px; margin-bottom: 0px;">
        Your order is being processed.<br>Do not click back or hit refresh!
      </p>
    </vs-popup>
    <div class="main_content">
      <div class="section page-section">
        <div class="container" v-if="cart.length === 0">
          <div class="row">
            <div class="col-lg-12" style="text-align: center;">
              <h5>Your cart is empty.</h5>
            </div>
          </div>
        </div>
        <div class="container" v-else>
          <div class="row">
            <div class="col-lg-6">
              <div class="toggle_info">
                <span><i class="fas fa-tag"></i><a href="#coupon" data-toggle="collapse" class="collapsed" aria-expanded="false"> Have a discount code? Click here to enter your code.</a></span>
              </div>
              <div class="panel-collapse collapse coupon_form" id="coupon">
                <div class="panel-body">
                  <p>If you have a discount code, please apply it below.</p>
                  <div class="coupon field_form input-group">
                    <fieldset style="width: 100%">
                      <div class="form-group">
                        <input type="text" value="" class="form-control" placeholder="Enter your code *" v-model="discountCode">
                        <small class="validation-error-text" v-if="discountCodeError">A discount code is required</small>
                      </div>
                      <div class="form-group">
                        <input type="text" value="" class="form-control" placeholder="Email address *" v-model="billingEmail" name="billingEmail" >
                        <small class="validation-error-text" v-if="discountCodeEmailError">An email is required</small>
                      </div>
                      <div class="form-group">
                        <button class="btn btn-fill-out btn-sm" style="float: right" @click="applyDiscount">Apply Discount</button>
                      </div>
                    </fieldset>
                  </div>
                  <div class="coupon field_form input-group">
                    <div style="margin: 0 auto; margin-top: 10px;">
                      <small v-if="discountError" class="validation-error-text">{{ discountError }}</small>
                      <small v-if="discountSuccess" style="color: var(--primary-color)">Discount has been applied!</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6" v-if="donateableProducts.length > 0">
              <div class="toggle_info">
                <span><i class="fas fa-gift" style="top: 3px;"></i><a href="#donate" data-toggle="collapse" aria-expanded="true" style="color: var(--primary-color); font-weight: bold"> Click below to donate a yearbook!</a></span>
                  <p style="font-size: 14px; margin-bottom: 0; margin-top: 5px; color: black">Donations are greatly appreciated and offer all students the opportunity to have a keepsake of their school year forever. Please consider adding one or more donated yearbooks to your purchase if you can. Thank you!</p>
              </div>
              <div class="panel-collapse coupon_form show" id="donate" style="margin-top: -10px;">
                <div class="panel-body">
                  <div v-for="(product, idx) in donateableProducts" v-bind:key="product.id">
                    <span>{{ product.name }}</span><br>
                    <span style="color: green !important;">${{ product.price.toFixed(2) }}</span><br>
                    <button class="btn btn-fill-out btn-addtocart btn-sm mt-1" type="button" @click="addDonatedItemToCart(product)">
                      Add to cart
                    </button>
                    <hr v-if="idx !== donateableProducts.length - 1">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="medium_divider"></div>
              <div class="divider center_icon"><i class="linearicons-credit-card"></i></div>
              <div class="medium_divider"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="heading_s1">
                <h4>Billing Details</h4>
              </div>
              <fieldset>
                <div class="form-group">
                  <input type="text" v-model="billingFname" class="form-control" name="billingFname" placeholder="First name *">
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingFname.required">First name is required</small>
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingFname.maxLength">First name cannot be more than 100 characters</small>
                </div>
                <div class="form-group">
                  <input type="text" v-model="billingLname" class="form-control" name="billingLname" placeholder="Last name *">
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingLname.required">Last name is required</small>
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingLname.maxLength">Last name cannot be more than 100 characters</small>
                </div>
                <div class="form-group">
                  <input type="text" v-model="billingAddress" class="form-control" name="billingAddress" placeholder="Address *">
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingAddress.required">Address is required</small>
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingAddress.maxLength">Address cannot be more than 100 characters</small>
                </div>
                <div class="form-group">
                  <input type="text" v-model="billingAddress2" class="form-control" name="billingAddress2" placeholder="Address 2">
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingAddress2.maxLength">Address 2 cannot be more than 100 characters</small>
                </div>
                <div class="form-group">
                  <input class="form-control" v-model="billingCity" type="text" name="billingCity" placeholder="City *">
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingCity.required">City is required</small>
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingCity.maxLength">City cannot be more than 100 characters</small>
                </div>
                <div class="form-group">
                  <div class="custom_select">
                    <select class="form-control" v-model="billingState" name="billingState">
                      <option value="">State *</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                      <option value="AB">Alberta</option>
                      <option value="BC">British Columbia</option>
                      <option value="MB">Manitoba</option>
                      <option value="NB">New Brunswick</option>
                      <option value="NL">Newfoundland and Labrador</option>
                      <option value="NS">Nova Scotia</option>
                      <option value="ON">Ontario</option>
                      <option value="PE">Prince Edward Island</option>
                      <option value="QC">Quebec</option>
                      <option value="SK">Saskatchewan</option>
                      <option value="NT">Northwest Territories</option>
                      <option value="NU">Nunavut</option>
                      <option value="YT">Yukon</option>
                    </select>
                    <small class="validation-error-text" v-if="$v.$anyError && !$v.billingState.required">State is required</small>
                  </div>
                </div>
                <div class="form-group">
                  <input class="form-control" type="text" v-model="billingZip" name="billingZip" placeholder="Zipcode *">
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingZip.required">Zipcode is required</small>
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingZip.maxLength">Zipcode cannot be more than 15 characters</small>
                </div>
                <div class="form-group">
                  <div class="custom_select">
                    <select class="form-control" v-model="billingCountry" name="billingCountry">
                      <option value="">Country *</option>
                      <option value="US">United States</option>
                      <option value="CA">Canada</option>
                    </select>
                    <small class="validation-error-text" v-if="$v.$anyError && !$v.billingCountry.required">Country is required</small>
                  </div>
                </div>
                <div class="form-group">
                  <input class="form-control" type="text" v-model="billingPhone" v-mask="'(###) ###-####'" name="billingPhone" placeholder="Phone *">
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingPhone.required">Phone is required</small>
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingPhone.maxLength">Phone cannot be more than 30 characters</small>
                </div>
                <div class="form-group">
                  <input class="form-control" type="text" v-model="billingEmail" name="billingEmail" placeholder="Email address *">
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingEmail.required">Email is required</small>
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingEmail.maxLength">Email cannot be more than 100 characters</small>
                  <small class="validation-error-text" v-if="$v.$anyError && !$v.billingEmail.email">A valid email is required</small>
                </div>
                <div class="ship_detail" v-if="shipsToHome">
                  <div class="form-group">
                    <div class="chek-form">
                      <div class="custome-checkbox">
                        <input class="form-check-input" type="checkbox" name="checkbox" id="differentaddress" v-model="useDifferentShippingAddress">
                        <label class="form-check-label label_info" for="differentaddress"><span>Ship to a different address?</span></label>
                      </div>
                    </div>
                  </div>
                  <div class="different_address" v-if="useDifferentShippingAddress">
                    <div class="form-group">
                      <input type="text" class="form-control" name="shippingFname" placeholder="First name *" v-model="shippingFname">
                      <small class="validation-error-text" v-if="shippingValidationError.shippingFname">Shipping first name is required</small>
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" name="shippingLname" placeholder="Last name *" v-model="shippingLname">
                      <small class="validation-error-text" v-if="shippingValidationError.shippingLname">Shipping last name is required</small>
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" name="shippingAddress" required="" placeholder="Address *" v-model="shippingAddress">
                      <small class="validation-error-text" v-if="shippingValidationError.shippingAddress">Shipping address is required</small>
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" name="shippingAddress2" required="" placeholder="Address 2"  v-model="shippingAddress2">
                      <small class="validation-error-text" v-if="shippingValidationError.shippingAddress2">Shipping address 2 cannot be more than 100 characters</small>
                    </div>
                    <div class="form-group">
                      <input class="form-control" type="text" name="shippingCity" placeholder="City *" v-model="shippingCity">
                      <small class="validation-error-text" v-if="shippingValidationError.shippingCity">Shipping city is required</small>
                    </div>
                    <div class="form-group">
                      <div class="custom_select">
                        <select class="form-control" name="shippingState" v-model="shippingState">
                          <option value="">State *</option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                          <option value="AB">Alberta</option>
                          <option value="BC">British Columbia</option>
                          <option value="MB">Manitoba</option>
                          <option value="NB">New Brunswick</option>
                          <option value="NL">Newfoundland and Labrador</option>
                          <option value="NS">Nova Scotia</option>
                          <option value="ON">Ontario</option>
                          <option value="PE">Prince Edward Island</option>
                          <option value="QC">Quebec</option>
                          <option value="SK">Saskatchewan</option>
                          <option value="NT">Northwest Territories</option>
                          <option value="NU">Nunavut</option>
                          <option value="YT">Yukon</option>
                        </select>
                        <small class="validation-error-text" v-if="shippingValidationError.shippingState">Shipping state is required</small>
                      </div>
                    </div>
                    <div class="form-group">
                      <input class="form-control" type="text" name="shippingZip" placeholder="Zipcode *" v-model="shippingZip">
                      <small class="validation-error-text" v-if="shippingValidationError.shippingZip">Shipping zip is required</small>
                    </div>
                    <div class="form-group">
                      <div class="custom_select">
                        <select class="form-control" name="shippingCountry" v-model="shippingCountry">
                          <option value="">Country *</option>
                          <option value="US">United States</option>
                          <option value="CA">Canada</option>
                        </select>
                        <small class="validation-error-text" v-if="shippingValidationError.shippingCountry">Shipping country is required</small>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="col-md-6">
              <div class="order_review">
                <div class="heading_s1">
                  <h4>Order Summary</h4>
                </div>
                <div class="table-responsive order_table">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, idx) in cart" :key="idx">
                        <td>{{ item.product.name }} <span class="cart_count" style="margin-left: 1px; margin-right: 2px;" v-if="item.donated">D</span>&nbsp;&nbsp;&nbsp;<span class="product-qty">x {{ item.quantity }}</span></td>
                        <td>${{ (item.quantity * item.itemPrice).toFixed(2) }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Subtotal</th>
                        <td >${{ rawCartTotal.toFixed(2) }}</td>
                      </tr>
                      <tr v-if="shipsToHome">
                        <th>Shipping</th>
                        <th>+${{ shipping.cost.toFixed(2) }} <br><small>{{ shipping.calculation }}</small></th>
                      </tr>
                      <tr v-if="discount && discount.id">
                        <th>Discount</th>
                        <th>
                          <span v-if="discount.typeDiscount === 1">-{{ Number(discount.amount).toFixed(0) }}%</span>
                          <span v-else>-${{ Number(discount.amount).toFixed(2) }}</span>
                        </th>
                      </tr>
                      <template v-if="account.parentId === '87PNV2'">
                        <tr v-if="store.bParentsPayConvenienceFee && totalFee > 0">
                          <th>Convenience fee</th>
                          <th>+${{ totalFee.toFixed(2) }}</th>
                        </tr>
                      </template>
                      <template v-else>
                        <tr v-if="store.bParentsPayConvenienceFee && convenienceFee > 0">
                          <th>Convenience fee</th>
                          <th>+${{ convenienceFee.toFixed(2) }}</th>
                        </tr>
                        <tr v-if="store.bParentsPayConvenienceFee && dedicationAdFee > 0">
                          <th>Ad fee</th>
                          <th>+${{ dedicationAdFee.toFixed(2) }}</th>
                        </tr>
                      </template>
                      <tr v-if="store.bCollectSalesTax && salesTaxAmount > 0">
                        <th>Sales tax</th>
                        <th>+${{ salesTaxAmount.toFixed(2) }}</th>
                      </tr>
                      <tr>
                        <th>Total</th>
                        <td class="product-subtotal">${{ cartTotal.toFixed(2) }}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="payment_method" v-if="cartTotal !== 0">
                  <stripe-elements
                    ref="checkoutRef"
                    :pk="publishableKey"
                    :amount="cartTotal"
                    locale="en"
                    @token="tokenCreated"
                    @loading="loading = $event">
                  </stripe-elements>
                </div>
                <button class="btn btn-fill-out btn-block" :disabled="submittingOrder || placeOrderClicked" @click="triggerStripe">Place Order</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#card-errors {
  color: red;
}
</style>

<script>
import to                              from '@/utils/to'
import { mapGetters }                  from 'vuex'
import { required, maxLength, email }  from 'vuelidate/lib/validators'
import { StripeElements }              from 'vue-stripe-checkout'
import { storeCommonMixin }            from '@/mixins/storeCommon'
import Breadcrumbs                     from '@/components/layout/header/Breadcrumbs'
import RepositoryFactory               from '@/repositories/RepositoryFactory'
const OnlineOrderRepository            = RepositoryFactory.get('online_orders')
const OnlineOrderItemRepository        = RepositoryFactory.get('online_order_items')
const CustomerIoActionRepository       = RepositoryFactory.get('customer_io_actions')
const DiscountRepository               = RepositoryFactory.get('discounts')
import { sendStripeDeclined, submitAdToBeProcessed, sendOrderConfirmationEmail, queueMarketingCampaignCheck } from '@/rabbitMq/queue_message'
let $ = window.$

export default {
  name: 'Checkout',
  mixins: [storeCommonMixin],
  components: { StripeElements, Breadcrumbs },
  computed: {
    ...mapGetters([
      'account',
      'bulkShipToHome',
      'cart',
      'cartTotal',
      'convenienceFee',
      'dedicationAdFee',
      'rawCartTotal',
      'shipping',
      'shipsToHome',
      'store',
      'summerShipToHome',
      'totalFee',
      'discount',
      'donateableProducts',
      'salesTaxAmount',
      'maxProductYear'
    ]),
    usingDiscount () {
      return this.discount && this.discount.id
    }
  },
  data () {
    return {
      loading: false,
      // Stripe info
      publishableKey : process.env.VUE_APP_STRIPE_KEY,
      token          : null,
      charge         : null,
      // billing info
      billingFname    : '',
      billingLname    : '',
      billingAddress  : '',
      billingAddress2 : '',
      billingCity     : '',
      billingState    : '',
      billingZip      : '',
      billingCountry  : '',
      billingEmail    : '',
      billingPhone    : '',
      // shipping info
      useDifferentShippingAddress : false,
      shippingFname               : '',
      shippingLname               : '',
      shippingAddress             : '',
      shippingAddress2            : '',
      shippingCity                : '',
      shippingState               : '',
      shippingZip                 : '',
      shippingCountry             : '',
      shippingValidationError     : {
        shippingFname    : false,
        shippingLname    : false,
        shippingAddress  : false,
        shippingAddress2 : false,
        shippingCity     : false,
        shippingState    : false,
        shippingZip      : false,
        shippingCountry  : false,
      },
      // extra information
      discountCode      : '',
      applyingDiscount  : false,
      discountError     : '',
      discountSuccess   : false,
      instructions      : '',
      submittingOrder   : false,
      placeOrderClicked : false,
      triggeringStripe  : false,
      discountCodeEmailError : false,
      discountCodeError : false
    }
  },
  watch: {
    useDifferentShippingAddress (val) {
      if (val) {
        this.shippingFname = ''
        this.shippingLname = ''
        this.shippingAddress = ''
        this.shippingAddress2 = ''
        this.shippingCity = ''
        this.shippingState = ''
        this.shippingZip = ''
        this.shippingCountry = ''
      } else {
        this.setSameShippingAsBilling()
      }
    }
  },
  validations: {
    billingFname    : { required, maxLength: maxLength(100) },
    billingLname    : { required, maxLength: maxLength(100) },
    billingAddress  : { required, maxLength: maxLength(100) },
    billingAddress2 : { maxLength: maxLength(100) },
    billingCity     : { required, maxLength: maxLength(100) },
    billingState    : { required },
    billingZip      : { required, maxLength: maxLength(15) },
    billingCountry  : { required },
    billingEmail    : { required, maxLength: maxLength(100), email },
    billingPhone    : { required, maxLength: maxLength(30) }
  },
  methods: {
    /**
     * Triggers stripe submit after validations
     */
    triggerStripe () {
      this.placeOrderClicked = true
      this.triggeringStripe  = true

      // No store or account, stop and go home!
      if (!this.store.id || !this.account.id) {
        this.placeOrderClicked = false
        this.triggeringStripe  = false
        window.location.href = '/'
        return
      }

      // Cart contains products not from this store, stop!
      if (this.checkCrossStoreOrder(this.store.id, this.cart)) {
        this.placeOrderClicked = false
        this.triggeringStripe  = false
        return
      }

      // Check for expired products
      for (let ci = 0; ci < this.cart.length; ci++) {
        if (this.cart[ci].itemPrice < 0 ||
          this.getProductPrice(this.cart[ci].product, this.account.timezone) < 0) { // Expired product
          this.$vs.notify({
            color    : 'danger',
            title    : 'Warning',
            text     : 'One or more products in your cart have expired and cannot be ordered at this time.',
            position : 'top-right'
          })
          this.placeOrderClicked = false
          this.triggeringStripe  = false
          return
        }
      }

      $('#card-errors').text('') // Clear card errors
      if (!this.useDifferentShippingAddress) this.setSameShippingAsBilling() // Set shipping data if needed

      // Trigger all validations
      this.$v.$touch()
      const shippingValidationError = this.useDifferentShippingAddress && this.shipsToHome && this.validateShipping()
      const err = this.$v.$invalid || shippingValidationError
      if (err) {
        this.$vs.notify({
          color:    'danger',
          title:    'Warning',
          text:     'Missing or required fields require attention',
          position: 'top-right'
        })
        this.placeOrderClicked = false
        this.triggeringStripe  = false
        return
      }
      // Trigger token created
      const self = this
      setTimeout(function () {
        self.placeOrderClicked = false
      }, 2000)
      if (this.cartTotal === 0) this.submitOrder({ id: 'FREE' })
      else this.$refs.checkoutRef.submit()
    },
    /**
     * Called when Stripe token object created
     * @param token - Stripe token
     */
    tokenCreated (token) {
      if (!this.triggeringStripe) return // Didn't come through validation, user hit ENTER
      this.triggeringStripe = false
      this.submittingOrder = true
      if (token.error) {
        this.submittingOrder = false
        this.anErrorOccurred()
        throw new Error(token.error)
      } else {
        this.submitOrder(token)
      }
    },
    /**
     * Submis an online order
     */
    submitOrder: async function (token) {
      this.submittingOrder = true
      // Build and save order
      const builtOrder = this.buildOrder()
      let error, oo;
      [error, oo] = await to(OnlineOrderRepository.create(builtOrder))
      if (!oo || oo.data.length === 0) {  // Failed to create order, notify user and throw an error
        this.submittingOrder = false
        this.anErrorOccurred()
        throw new Error(error)
      }

      let onlineOrder = oo.data[0]
      // Create order items associated with the order
      let onlineOrderItems = []
      for (let i = 0; i < this.cart.length; i++) {
        let orderItem = this.buildOrderItem(onlineOrder, this.cart[i])
        let ooi;
        [error, ooi] = await to(OnlineOrderItemRepository.create(orderItem))
        if (!ooi || ooi.data.length === 0) { // Failed to create an order item, notify user and throw an error
          this.submittingOrder = false
          if (ooi && ooi.data && ooi.data.length === 0 && ooi.total === 1) { // Product expired
            this.$vs.notify({
              time     : 6000,
              color    : 'danger',
              title    : 'Error',
              text     : 'One or more products in your cart have expired and cannot be ordered at this time.',
              position : 'top-right'
            })
            throw new Error(`Product '${orderItem.productName}' in ${this.account.name}'s store has expired. Attempted purchase.'`)
          } else if (ooi && ooi.data && ooi.data.length === 0 && ooi.total === 2) { // Shipping required
            this.$vs.notify({
              time     : 6000,
              color    : 'danger',
              title    : 'Error',
              text     : `The school's shipping settings have changed during your shopping session. Redirecting you to the landing page to re-open their store.`,
              position : 'top-right'
            })
            setTimeout(function() {
              window.location.href = 'https://shop.yearbookmarket.com'
            }, 6000)
            throw new Error(`Store for ${this.account.name} shipping settings have changed.'`)
          }
          this.anErrorOccurred()
          throw new Error(error)
        }
        onlineOrderItems.push(ooi.data[0])
      }

      // Submit charge
      let chargeResponse;
      [error, chargeResponse] = await this.submitCharge(token, onlineOrder)
      if (!chargeResponse) { // Charge failed
        this.submittingOrder = false
        this.anErrorOccurred()
        throw new Error(error)
      }

      // Check for transaction error (no transaction ID or not paid)
      if (!chargeResponse.id || !chargeResponse.paid) {
        console.log('Sending stripe declined message')
        sendStripeDeclined(onlineOrder, this.account.name, chargeResponse)
        const declinedReasonText = chargeResponse.message
        let uoo;
        [error, uoo] = await to(OnlineOrderRepository.update({ declinedReason: declinedReasonText }, onlineOrder.id))
        if (!uoo) {
          this.anErrorOccurred()
          throw new Error(error)
        }
        this.submittingOrder = false
        $('#card-errors').text('Your payment failed to process. Reason: ' + declinedReasonText)
      } else {
        let paymentId = chargeResponse.id

        // Update discount code
        if (this.discount && this.discount.id) {
          DiscountRepository.update(
            { bUsed: true,
              billingFname: onlineOrder.billingFname,
              billingLname: onlineOrder.billingLname,
              onlineOrderId: onlineOrder.id },
              this.discount.id)
            .then((response) => {
              console.log(response)
            }).catch((err) => {
              console.log(err)
            })
        }

        if (this.account.parentId === 'S00017') { // Wagner is using customer.io
          CustomerIoActionRepository.create({
            accountId  : this.account.id,
            typeAction : 1,
            phone      : onlineOrder.billingPhone,
            email      : $.trim(onlineOrder.billingEmail)
          }).then((response) => {
            console.log(response)
          }).catch((err) => {
            console.log(err)
          })
        }

        console.log('Sending order confirmation email')
        this.sendConfirmation(onlineOrder, onlineOrderItems)
        // If ads are present, queue for notification
        for (let i = 0; i < this.cart.length; i++) {
          let item = this.cart[i]
          if (item.ad) {
            console.log('Submitting ad to be processed')
            submitAdToBeProcessed(item.ad, onlineOrder, this.account, item.student)
          }
        }

        // Queues a marketing campaign check
        try {
          queueMarketingCampaignCheck(onlineOrder, this.maxProductYear)
        } catch (marketingErr) {
          console.log(`Failed to queue a marketing campaign check: ${marketingErr}`)
        }

        // Route to order confirmation
        this.$router.push({ name: 'OrderConfirmation', params: { link: this.store.link, transactionId: paymentId, orderId: onlineOrder.id }})
        this.submittingOrder = false
      }
    },
    /**
     * Submits a charge to be billed by Stripe
     * @param token - Stripe token
     * @param onlineOrder - Online order object
     */
    submitCharge: async function (token, onlineOrder) {
      // Build data for charge
      let data = {
        token         : token.id === 'FREE' ? token.id + onlineOrder.id : token.id,
        total         : this.cartTotal * 100,
        currency      : this.store.typeCurrency ? this.store.typeCurrency : 'usd',
        name          : this.account.name,
        accountId     : this.account.id,
        parentId      : this.account.parentId,
        onlineOrderId : onlineOrder.id,
        billingFname  : onlineOrder.billingFname,
        billingLname  : onlineOrder.billingLname,
        code          : (this.discount && this.discount.id) ? this.discount.code : `ORDER${onlineOrder.id}`,
        amount        : (this.discount && this.discount.id) ? Number(this.discount.amount) : 0,
        typerDiscount : (this.discount && this.discount.id) ? this.discount.typeDiscount : 0
      }
      if (this.cartTotal === 0) return to(OnlineOrderRepository.chargeFreeOrderStripe(data))
      else return to(OnlineOrderRepository.chargeStripeOrder(data))
    },
    /**
     * Send order confirmation email
     * @param onlineOrder - Online order object
     * @param onlineOrderItems - Online order item objects
     */
    sendConfirmation (onlineOrder, onlineOrderItems) {
        // Send invoice data
      onlineOrder['items']             = onlineOrderItems
      onlineOrder['invoiceDate']       = this.$options.filters.filterDate(new Date(), this.account.timezone)
      onlineOrder['bUsingDiscount']    = (this.discount && this.discount.id) ? true : false
      onlineOrder['discount']          = this.discount
      if (onlineOrder['bUsingDiscount']) {
        let fd = ''
        if (this.discount.typeDiscount === 1) fd = `-${Number(this.discount.amount).toFixed(0)}%`
        else fd = `-$${Number(this.discount.amount).toFixed(2)}`
        onlineOrder['formattedDiscount'] = fd
      }
      onlineOrder['originalCartTotal'] = this.cartTotal
      sendOrderConfirmationEmail(onlineOrder, this.account, this.store)
    },
    /**
     * Standard response for when an error occurs and no charge
     */
    anErrorOccurred () {
      this.$vs.notify({
        time     : 6000,
        color    : 'danger',
        title    : 'Error',
        text     : 'An error occurred while processing your order, but you have not been charged. We are investigating the issue.',
        position : 'top-right'
      })
    },
    /**
     * Set shipping adddress the same as billing
     */
    setSameShippingAsBilling () {
      this.shippingFname = this.billingFname
      this.shippingLname = this.billingLname
      this.shippingAddress = this.billingAddress
      this.shippingAddress2 = this.billingAddress2
      this.shippingCity = this.billingCity
      this.shippingState = this.billingState
      this.shippingZip = this.billingZip
      this.shippingCountry = this.billingCountry
    },
    /**
     * Validates shipping information
     */
    validateShipping () {
      if (this.shippingFname === '' || this.shippingFname.length > 100) this.shippingValidationError.shippingFname = true
      else this.shippingValidationError.shippingFname = false
      if (this.shippingLname === '' || this.shippingLname.length > 100) this.shippingValidationError.shippingLname = true
      else this.shippingValidationError.shippingLname = false
      if (this.shippingAddress === '' || this.shippingAddress.length > 100) this.shippingValidationError.shippingAddress = true
      else this.shippingValidationError.shippingAddress = false
      if (this.shippingAddress2.length > 100) this.shippingValidationError.shippingAddress2 = true
      else this.shippingValidationError.shippingAddress2 = false
      if (this.shippingCity === '' || this.shippingCity.length > 100) this.shippingValidationError.shippingCity = true
      else this.shippingValidationError.shippingCity = false
      if (this.shippingState === '') this.shippingValidationError.shippingState = true
      else this.shippingValidationError.shippingState = false
      if (this.shippingZip === '' || this.shippingZip.length > 15) this.shippingValidationError.shippingZip = true
      else this.shippingValidationError.shippingZip = false
      if (this.shippingCountry === '') this.shippingValidationError.shippingCountry = true
      else this.shippingValidationError.shippingCountry = false
      const self = this
      return Object.keys(this.shippingValidationError).some(k => self.shippingValidationError[k])
    },
    /**
     * Builds an order object
     */
    buildOrder () {
      let typeShipping = 1
      if (this.bulkShipToHome) typeShipping = 3
      if (this.summerShipToHome) typeShipping = 2
      return {
        accountId                : this.account.id,
        storeId                  : this.store.id,
        billingFname             : this.billingFname,
        billingLname             : this.billingLname,
        billingAddress           : this.billingAddress,
        billingAddress2          : this.billingAddress2,
        billingCity              : this.billingCity,
        billingState             : this.billingState,
        billingCountry           : this.billingCountry,
        billingZip               : this.billingZip,
        billingEmail             : this.billingEmail,
        billingPhone             : this.billingPhone,
        shippingFname            : this.useDifferentShippingAddress ? this.shippingFname : this.billingFname,
        shippingLname            : this.useDifferentShippingAddress ? this.shippingLname : this.billingLname,
        shippingAddress          : this.useDifferentShippingAddress ? this.shippingAddress : this.billingAddress,
        shippingAddress2         : this.useDifferentShippingAddress ? this.shippingAddress2 : this.billingAddress2,
        shippingCity             : this.useDifferentShippingAddress ? this.shippingCity : this.billingCity,
        shippingState            : this.useDifferentShippingAddress ? this.shippingState : this.billingState,
        shippingCountry          : this.useDifferentShippingAddress ? this.shippingCountry : this.billingCountry,
        shippingZip              : this.useDifferentShippingAddress ? this.shippingZip : this.billingZip,
        shippingMethod           : this.shipsToHome ? 'BP' : '',
        instructions             : this.instructions,
        typePayment              : 2, // CC
        typeShipping             : typeShipping,
        amountShipping           : this.shipsToHome ? this.shipping.cost : 0,
        total                    : this.cartTotal,
        convenienceFee           : this.store.bOverrideConvenienceFee ? 0 : this.convenienceFee,
        calculatedConvenienceFee : this.account.parentId === 'S00017' ? 0 : this.convenienceFee,
        dedicationAdFee          : this.dedicationAdFee,
        bDeclined                : true,
        typeCurrency             : this.store.typeCurrency || 'usd',
        bFeePaidByParent         : (this.store.bUsesServiceFee || this.store.bParentsPayConvenienceFee),
        amountFeePaid            : (this.store.bUsesServiceFee || this.store.bParentsPayConvenienceFee) ? this.convenienceFee : 0,
        salesTaxRate             : this.store.salesTaxRate,
        amountSalesTax           : this.salesTaxAmount,
        discountCode             : this.usingDiscount ? this.discount.code : '',
        discountType             : this.usingDiscount ? this.discount.typeDiscount : 0,
        discountAmount           : this.usingDiscount ? this.discount.amount : 0

      }
    },
    /**
     * Builds an order item
     * @param onlineOrder - Created online order
     * @param cartItem - Cart item
     */
    buildOrderItem (onlineOrder, cartItem) {
      return {
        onlineOrderId         : onlineOrder.id,
        productId             : cartItem.product.id,
        productName           : cartItem.product.name,
        productDescription    : cartItem.product.description,
        studentFname          : cartItem.student.firstName,
        studentLname          : cartItem.student.lastName,
        studentPersonalizedName : cartItem.student.personalizedName || '',
        studentGrade          : cartItem.student.grade,
        studentTeacher        : cartItem.student.teacher,
        message               : cartItem.message,
        bPersonalized         : cartItem.personalized,
        typePersonalization   : cartItem.typePersonalization,
        amountPersonalization : cartItem.personalized ? Number(cartItem.personalizationCost) : 0,
        typeShipping          : cartItem.product.typeShipping,
        typeProduct           : cartItem.product.typeProduct,
        productBasePrice      : cartItem.productPrice,
        productTotalPrice     : Number(cartItem.itemPrice),
        quantity              : Number(cartItem.quantity),
        total                 : Number(cartItem.quantity) * Number(cartItem.itemPrice),
        year                  : cartItem.product.year ? cartItem.product.year : 0,
        typeBook              : cartItem.product.typeBook ? cartItem.product.typeBook : 0,
        dedicationAdId        : cartItem.ad ? cartItem.ad.id : null,
        bDonated              : cartItem.donated || false,
        amountShipToHomePrice : (Number(cartItem.product.amountShipToHomePrice) || 0).toFixed(2),
        amountShipToHomeTotal : ((Number(cartItem.product.amountShipToHomePrice) * Number(cartItem.quantity)) || 0).toFixed(2)
      }
    },
    /**
     * Applies a discount at checkout
     */
    async applyDiscount () {
      // Validate code
      if ($.trim(this.discountCode) === '') {
        this.discountCodeError = true
        this.$store.dispatch('setDiscount', null)
        this.discountSuccess = false
      } else {
        this.discountCodeError = false
      }
      // Validate email
      if ($.trim(this.billingEmail) === '') {
        this.discountCodeEmailError = true
        this.$store.dispatch('setDiscount', null)
        this.discountSuccess = false
      } else {
        this.discountCodeEmailError = false
      }
      // Exit if either validation error is triggered
      if (this.discountCodeError || this.discountCodeEmailError) {
        return
      }

      // Clean up code
      let code = $.trim(this.discountCode)
      code = code.replace(/\W/g, '')
      // Clean up email
      let email = $.trim(this.billingEmail)
      this.applyingDiscount = true
      // Check if discount code is valid
      const [discountErr, discount] = await to(DiscountRepository.checkIfValid({
        code: code,
        year: this.maxProductYear,
        email: email
      }))
      if (!discount) {
        this.discountError = 'An error occurred. We have been notified.'
        this.$store.dispatch('setDiscount', null)
        this.discountSuccess = false
        throw new Error(discountErr)
      }

      // A code will be returned on success
      if (discount.total === 0) {
        this.discountError = 'Not a valid discount code.'
        this.$store.dispatch('setDiscount', null)
        this.discountSuccess = false
      } else {
        this.discountError = ''
        this.$store.dispatch('setDiscount', discount.data[0])
        this.discountSuccess = true
      }
      this.applyingDiscount = false
    },
    /**
     * Adds item to cart
     */
    addDonatedItemToCart (product) {
      if (this.checkCrossStoreOrder(this.store.id, this.cart, product.storeId)) return
      if (this.checkCrossShippingOrder(product.typeShipping, this.cart)) return
      if (this.checkSummerSingleSameYear(product.typeShipping, product.year, this.cart)) return
      if (this.getProductPrice(product, this.account.timezone) < 0) {
        this.$vs.notify({
          color    : 'danger',
          title    : 'Warning',
          text     : 'This product has expired and cannot be donated at this time.',
          position : 'top-right'
        })
        return
      }
      let item = {
        donated: true,
        itemPrice:  product.price,
        message: '',
        personalizationCost: 0,
        personalized: false,
        product: product,
        productPrice: product.price,
        project: null,
        quantity: 1,
        storeId: this.store.id,
        storeLink: this.store.link,
        student: {
          firstName : 'Donate',
          lastName  : 'Donate',
          teacher   : 'Donate',
          grade     : 'Donate',
          personalizedName: ''
        },
        typePersonalization: 0
      }
      this.$store.dispatch('addItemToCart', item)
      this.$vs.notify({
        color    : 'success',
        title    : 'Added to Cart',
        text     : 'Thank you for donating a yearbook!',
        position : 'top-right'
      })
    }
  },
  mounted () {
    if (this.discount && this.discount.id) {
      this.discountCode = this.discount.code
      this.applyDiscount()
    }
  }
}
</script>
